import * as React from 'react';

import { ScrollLink } from 'src/explore/elements/ScrollLink';

import styles from './index.module.sass';

interface Props {
  offset?: string;
}

export const SkipToContent: React.FC<Props> = ({ offset }) => (
  <ScrollLink
    className={`${styles.link} position-fixed py-2 px-4 rounded-bottom text-white text-decoration-none font-weight-bold`}
    target="#content"
    targetOffset={offset}
  >
    Skip to Content
  </ScrollLink>
);
